<template>
  <Modal
    v-model="value"
    title="选择养殖场"
    @input="(val) => $emit('input', val)"
    :width="600"
    @on-visible-change="modalShow"
  >
    <!-- <div style="width: 100%; height: 650px"> -->
      <x-table
        :columns="table.columns"
        :data="table.data"
        :loading="table.loading"
        :config="config"
        @search="search"
        @page-change="pageChange"
        @page-size-change="pageSizeChange"
        @on-row-click="rowClick"
      ></x-table>
    <!-- </div> -->

    <p slot="footer" style="text-align:center;">
      <Button type="primary" @click="submit">提交</Button>
      <Button @click="() => $emit('input', false)">取消</Button>
    </p>
  </Modal>
</template>

<script>
export default {
  name: "",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    farmId: {
      type: [String, Number],
      default: "",
    },
    options: Object,
    isDefault: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: " ",
            width: 70,
            render: (h, { row }) => {
              if (row.id == this.currentFarm.id) {
                return <Icon type="md-checkmark" />;
              }
            },
          },
          {
            title: "养殖场名称",
            key: "farmerName",
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        page_size: "small",
      },
      search_data: {},
      currentFarm: {},
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          width: 220,
          filterBox: [
            {
              conditionName: "养殖场名称",
              component: "input",
              field: "farmerName",
              defaultValue: "",
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    modalShow(visible) {
      if (visible) {
        this.getList();
      }
    },
    getList() {
      this.table.loading = true;
      this.$post(this.$api.FAMRMING.LIST, {
        ...this.options,
        //siteType: 2,
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
          if (this.farmId) {
            let n = res.list.find((item) => item.id == this.farmId);
            if (n) this.currentFarm = n;
          }
          if (this.isDefault && !this.farmId) {
            this.$emit('sel-finish', res.list[0])
          }
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    search(val) {
      this.search_data = val;
      this.getList();
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    rowClick(row, index) {
      this.currentFarm = row;
    },
    submit() {
      this.$emit('sel-finish', this.currentFarm)
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<style lang="less" scoped>
/deep/ .ivu-table-wrapper {
  height: 520px !important;
}
</style>